import React, { useState } from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby"
import htmlDecode from "html-entities-decoder"

import "./Services.css";


function Services(props) {
    const data = props.data;
    return (
        <div className="pt-16 md:pt-16" style={props.stylez ? props.stylez : { display: "block" }}>
            <div className="m-auto text-left container">
                {data.title && (
                    <>
                        {/* <p className="section-name">{data.sectionName}</p> */}
                        <h2 className="pl-4 md:pl-8" style={ props.isProject ? {color: "white"} : {color: "initial"}} >
                            {data.title}
                        </h2>
                        {/* <p className="m-auto mt-4" style={{ maxWidth: "500px" }}>{data.servicesDescription}</p> */}
                    </>
                )}
                {props.data.services.map((services, index) => (
                    <div key={`services-${services.node.name}`} className="pb-8 text-left" >
                        <div className="container m-auto">
                            <div className="flex flex-wrap py-4">
                                {services.node.services && services.node.services.edges.map((service, yndex) => (
                                    <div key={`service-${service.node.title}`} className="w-full md:w-1/3 flex relative">
                                        <Link to={`${service.node.uri}`} className="m-8 m-1 service-tab relative" style={{ backgroundColor: "#f6faff" }} >
                                            <div>
                                                <GatsbyImage
                                                    image={service.node.servicesAcf.smallIcon.imageFile.childImageSharp.gatsbyImageData}
                                                    alt={service.node.servicesAcf.smallIcon.altText}
                                                    className=" m-auto"
                                                    style={{ maxHeight: "275px" }}
                                                    loading={"eager"}
                                                />
                                            </div>
                                            {data.title ?
                                                <h3 className="text-2xl py-2 services-h4 px-6 service-h4" style={{color: "#000"}}>
                                                    {htmlDecode(service.node.title)}
                                                </h3>
                                                :
                                                <h2 className="text-2xl py-4 px-6 service-h4" style={{color: "#000"}}>
                                                    {htmlDecode(service.node.title)}
                                                </h2>
                                            }
                                        </Link>
                                    </div>
                                ))}

                                {services.node.projects && services.node.projects.edges.map((service, yndex) => (
                                    <div key={`service-${service.node.title}`} className="w-full md:w-1/3 flex relative">
                                        <Link to={`${service.node.uri}`} className="m-8 m-1 service-tab relative">
                                            <div>
                                                <GatsbyImage
                                                    image={service.node.servicesAcf.smallIcon.imageFile.childImageSharp.gatsbyImageData}
                                                    alt={service.node.servicesAcf.smallIcon.altText}
                                                    className="m-auto"
                                                    style={{ maxHeight: "275px" }}
                                                />
                                            </div>
                                            {data.title ?
                                                <h3 className="text-2xl py-2 services-h4 px-6 service-h4" style={{color: "white"}}>
                                                    {htmlDecode(service.node.title)}
                                                </h3>
                                                :
                                                <h2 className="text-2xl py-4 px-6 service-h4" style={{color: "white"}}>
                                                    {htmlDecode(service.node.title)}
                                                </h2>
                                            }
                                            {/* <div dangerouslySetInnerHTML={{ __html: service.node.excerpt }} className="px-6" /> */}
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Services;
