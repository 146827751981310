import React, { useState, useEffect, useRef } from "react"
import { graphql, Link } from "gatsby"

import Layout from '../components/layout'
import Introduction from '../components/Home/Introduction'
import Services from '../components/Home/Services'
import Last from '../components/Home/Last'

function HomeTemplate(props) {
    // console.log(props)
    let data = props.data.wpgraphql;

    const [y, setY] = useState();
    const [espaV, setEspaV] = useState(true);

    const handleNavigation = (e) => {
        const window = e.currentTarget;
        if (0 === window.scrollY) {
            setEspaV(true)
        } else {
            setEspaV(false)
        }
        setY(window.scrollY);
    };

    useEffect(() => {
        setY(window.scrollY)
    },[])

    useEffect(() => {
        window.addEventListener("scroll", (e) => handleNavigation(e));

        return () => { // return a cleanup function to unregister our function since its gonna run multiple times
            window.removeEventListener("scroll", (e) => handleNavigation(e));
        };
    }, [y]);


    function constructMetaData(page, currentPage, language) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://karanikolascon.gr${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://karanikolascon.gr${currentPage}`,
            fbType: "website",
            locale: language.toLowerCase(),
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }


    return (
        <Layout
            headerMenu={props.pageContext.headerMenu}
            footerMenu={props.pageContext.footerMenu}

            currentPage={{ currentPage: props.pageContext.currentPage, language: props.pageContext.languageName }}
            availableVersions={props.pageContext.availablePages}
            metaData={constructMetaData(data.page, props.pageContext.currentPage, props.pageContext.language)}
            services={props.pageContext.servicesCategories}
            projects={props.pageContext.projectsCategories}
            preHeader={data.template.contactInformationTemplate}
            absoluteHeader={true}
        >
            <div>
                <section>
                    <Introduction data={data.page.homePageAcf} />
                </section>
                <section>
                    <Services
                        data={{
                            title: data.page.homePageAcf.servicesTitle,
                            services: data.serviceCategories.edges,
                            sectionName: data.page.homePageAcf.servicesSectionName,
                            servicesDescription: data.page.homePageAcf.servicesDescription,
                            servicesReadMore: data.page.homePageAcf.servicesReadMore
                        }}
                    />
                </section>

                <section>
                    <Services
                        data={{
                            title: data.page.homePageAcf.projectsTitle,
                            services: data.projectCategories.edges,
                            sectionName: data.page.homePageAcf.projectsSectionName,
                            servicesDescription: data.page.homePageAcf.projectsDescription,
                            servicesReadMore: data.page.homePageAcf.projectsReadMore
                        }}
                        stylez={{ backgroundColor: "black" }}
                        isProject={true}
                    />
                </section>
                {/* <section>
                    <Last data={data.page.homePageAcf.last} />
                </section> */}

            </div>

            { props.pageContext.language.toLowerCase() === "el"
                ?
                <a href="https://admin.karanikolascon.gr/wp-content/uploads/2021/07/VIMA_WEB3b.pdf" target="_blank" rel="noopener"  className="espa-container" style={espaV ? { opacity: "1" } : { opacity: "0", zIndex: "-1" }}>
                    <img src="https://admin.karanikolascon.gr/wp-content/uploads/2021/07/espa-el.jpg" alt="espa.logo" />
                </a>
                :
                <a href="https://admin.karanikolascon.gr/wp-content/uploads/2021/07/VIMA_WEB3bENb-en.pdf" target="_blank" rel="noopener" className="espa-container" style={espaV ? { opacity: "1" } : { opacity: "0", zIndex: "-1" }}>
                    <img src="https://admin.karanikolascon.gr/wp-content/uploads/2021/07/espa-en.jpg" alt="espa.logo" />
                </a>
            }

        </Layout>
    )
}

export default HomeTemplate

export const pageQuery = graphql`query GET_PAGE($id: ID!, $language: WPGraphQL_LanguageCodeFilterEnum, $themeTemplateUri: ID!) {
  wpgraphql {
    page(id: $id) {
      seo {
        canonical
        title
        metaDesc
        opengraphDescription
        opengraphTitle
        opengraphImage {
          sourceUrl
        }
      }
      homePageAcf {
        ...IntroductionFragment
    
        servicesTitle
        servicesSectionName
        servicesDescription
        servicesReadMore
        projectsTitle
        projectsSectionName
        projectsDescription
        projectsReadMore

        last {
            title
            bgimage {
                altText
                sourceUrl
                imageFile {
                    childImageSharp {
                        gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
                    }
                }
            }
            bttn {
                label
                cta
            }
        }
      }
    }
    serviceCategories(where: {language: $language}) {
      edges {
        node {
          name
          services(first: 200, where: {orderby: {field: MENU_ORDER, order: ASC}}) {
            edges {
              node {
                uri
                title
                excerpt
                servicesAcf {
                  smallIcon {
                    altText
                    sourceUrl
                    imageFile {
                      childImageSharp {
                        gatsbyImageData(quality: 100, layout: CONSTRAINED, width:576, placeholder: BLURRED)
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    projectCategories(where: {language: $language}) {
      edges {
        node {
          name
          projects(first: 200, where: {orderby: {field: MENU_ORDER, order: ASC}}) {
            edges {
              node {
                uri
                title
                excerpt
                servicesAcf {
                  smallIcon {
                    altText
                    sourceUrl
                    imageFile {
                      childImageSharp {
                        gatsbyImageData(quality: 100, layout: CONSTRAINED, width:576, placeholder: BLURRED)
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    
    template(id: $themeTemplateUri, idType: SLUG) {
      contactInformationTemplate {
        telephoneNumber
        mobileNumber
        supportEmail
        hoursLabel
        hoursTitle
        form {
          title
          fullName
          email {
            label
            errorMessage
          }
          subject
          message {
            label
            errorMessage
          }
          telephone {
            label
            errorMessage
          }
          sendButton
        }
        responseMessages {
          success
          failed
        }
      }
    }
  }
}
`
